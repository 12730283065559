import React from 'react';
import { Routes, Route } from 'react-router-dom';

const LayoutAdmin = React.lazy(() => import('../layouts/admin2'));
const Dashboard = React.lazy(() => import('../pages/DashboardAdmin'));
const Admins = React.lazy(() => import('../pages/Admins'));
const Profissionais = React.lazy(() => import('../pages/Profissionais'));
const Profissional = React.lazy(() => import('../pages/Profissional'));
const Clientes = React.lazy(() => import('../pages/Clientes'));
const Cliente = React.lazy(() => import('../pages/Cliente'));
const Fornecedores = React.lazy(() => import('../pages/Fornecedores'));
const TipoDeAtendimentos = React.lazy(() => import('../pages/Especialidades'));
const Grupos = React.lazy(() => import('../pages/Grupos'));
const TipoAtendimentos = React.lazy(() => import('../pages/TipoAtendimentos'));
const SalaAtendimentos = React.lazy(() => import('../pages/SalaAtendimentos'));
const Status = React.lazy(() => import('../pages/Status'));
const TabelaValoresCliente = React.lazy(() => import('../pages/TabelaValoresClientes'));
const TabelaValoresEspecialista = React.lazy(() => import('../pages/TabelaValoresEspecialistas'));
const Financeiro = React.lazy(() => import('../pages/Financeiro'));
const ContaPagar = React.lazy(() => import('../pages/ContaPagar'));
const ContaReceber = React.lazy(() => import('../pages/ContaReceber'));
const Relatorios = React.lazy(() => import('../pages/Relatorios'));
const SolicitacaoExclusaoConta = React.lazy(() => import('../pages/SolicitacaoExclusaoConta'));

function AppAdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutAdmin />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/administradores" element={<Admins />} />
        <Route path="/profissionais" element={<Profissionais />} />
        <Route path="/profissional" element={<Profissional />} />
        <Route path="/profissional/:id" element={<Profissional />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/cliente/:id" element={<Cliente />} />
        <Route path="/fornecedores" element={<Fornecedores />} />
        <Route path="/especialidades" element={<TipoDeAtendimentos />} />
        <Route path="/grupos" element={<Grupos />} />
        <Route path="/tipos-atendimentos" element={<TipoAtendimentos />} />
        <Route path="/salas-atendimentos" element={<SalaAtendimentos />} />
        <Route path="/status" element={<Status />} />
        <Route path="/tabela-valores-clientes" element={<TabelaValoresCliente />} />
        <Route path="/tabela-valores-especialistas" element={<TabelaValoresEspecialista />} />
        <Route path="/financeiro" element={<Financeiro />} />
        <Route path="/conta-pagar" element={<ContaPagar />} />
        <Route path="/conta-receber" element={<ContaReceber />} />
        <Route path="/relatorios" element={<Relatorios />} />
        <Route path="/solicitar-exclusao-conta" element={<SolicitacaoExclusaoConta />} />
        <Route path="/solicitar-exclusao-conta/:email" element={<SolicitacaoExclusaoConta />} />
      </Route>
    </Routes>
  );
}

export default AppAdminRoutes;
