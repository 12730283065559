/* eslint-disable no-return-assign */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import { ID_KEY, TOKEN_KEY, USER_NAME } from '../constants/auth';
import api from '../services/api';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [loggedUser, setLoggedUser] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!userToken) {
        if (mounted) {
          setIsAuthenticated(false);
        }
        return;
      }

      try {
        const { data } = await api.get('/getAdmins');
        if (mounted) {
          if (data) {
            setIsAuthenticated(true);
          }
          setLoggedUser(data);
        }
      } catch (error) {
        console.log(error);
        if (mounted) {
          setIsAuthenticated(false);
        }

        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();

    return () => mounted = false;
  }, [userToken]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      userToken,
      loggedUser,
      setLoggedUser,
      signInAdmin: (token, user) => {
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(ID_KEY, user.id || user._id);
        localStorage.setItem(USER_NAME, user.name);
        // setIsAuthenticated(true);
        setUserToken(token);
      },
      signOutAdmin: async () => {
        console.log('sign out');
        await api.delete('/logoutAdmins');
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        setLoggedUser(null);
        setIsAuthenticated(false);
        setUserToken(null);
      },
    }),
    [
      userToken,
      isAuthenticated,
      loggedUser,
    ],
  );

  useEffect(() => {
    let mounted = true;
    const tokenUser = localStorage.getItem(TOKEN_KEY);

    if (tokenUser && mounted) {
      setIsAuthenticated(true);
      setUserToken(tokenUser);
    }

    return () => mounted = false;
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthProvider;
