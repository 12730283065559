import React from 'react';

const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));
const SolicitacaoExclusaoConta = React.lazy(() => import('../pages/SolicitacaoExclusaoConta'));
/* const Register = React.lazy(() => import('../pages/Register'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const RecoverPassword = React.lazy(() => import('../pages/RecoverPassword')); */

export default [
  { path: '/', Component: LoginAdmin },
  { path: '/login-admin', Component: LoginAdmin },
  { path: '/solicitar-exclusao-conta', Component: SolicitacaoExclusaoConta },
  { path: '/solicitar-exclusao-conta/:email', Component: SolicitacaoExclusaoConta },
  /* { path: '/cadastro', Component: Register },
  { path: '/esquecisenha', Component: ForgetPassword },
  { path: '/alterarsenha/:token', Component: RecoverPassword }, */
  { path: '*', Component: LoginAdmin },
];
