import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import Loader from '../components/FullScreenLoader';
import Notification from '../components/Notification';
import { SIDEBARCOLLAPSED, SIDEBARSHOW } from '../constants/app';

export const AppContext = createContext();

function AppProvider({ children }) {
  const notificationRef = useRef();
  const [loading, setLoading] = useState(false);
  const [contentHeight, setContentHeight] = useState('600');
  const [sidebarShow, setSidebarShow] = useState('opened');
  const [collapsedSidebar, setCollapsedSidebar] = useState('opened');
  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(true);

  const toggleSidebar = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  const toggleCollapsedSidebarSidebar = useCallback(() => {
    setCollapsedSidebar((prevState) => {
      localStorage.setItem(SIDEBARCOLLAPSED, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  useEffect(() => {
    setSidebarShow(localStorage.getItem(SIDEBARSHOW) || 'opened');
    setCollapsedSidebar(localStorage.getItem(SIDEBARCOLLAPSED) || 'opened');
  }, []);

  const value = useMemo(
    () => ({
      loading,
      contentHeight,
      sidebarShow,
      collapsedSidebar,
      toggleSidebar,
      toggleCollapsedSidebarSidebar,
      navbarTogglerOpen,
      notificationRef,
      setNavbarTogglerOpen,
      setLoading,
      setContentHeight,
    }),
    [
      loading,
      contentHeight,
      sidebarShow,
      collapsedSidebar,
      toggleSidebar,
      toggleCollapsedSidebarSidebar,
      navbarTogglerOpen,
      notificationRef,
    ],
  );

  return (
    <AppContext.Provider value={value}>
      <Loader loading={loading} />
      <Notification ref={notificationRef} />
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
